'use client'

import Cookies from 'js-cookie'
import {AlertCircle, Eye, EyeOff, Loader2, PartyPopperIcon} from 'lucide-react'
import {signIn} from 'next-auth/react'
import Link from 'next/link'
import {useRouter, useSearchParams} from 'next/navigation'
import {useEffect, useState} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {z} from 'zod'

import ForgotPasswordDialog from '@/app/(auth)/auth/login/forgot-password-dialog'
import {Alert, AlertDescription, AlertTitle} from '@/components/ui/alert'
import {Button} from '@/components/ui/button'
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from '@/components/ui/form'
import {Input} from '@/components/ui/input'
import useMobileAppDetect from '@/lib/device-detect'
import {zodResolver} from '@hookform/resolvers/zod'

const LoginFormSchema = z.object({
  email: z.string().email({message: 'Enter valid email address(Example: john@gmail.com)'}),
  password: z.string().min(1, 'Please enter a password'),
})

export type LoginFormValues = z.infer<typeof LoginFormSchema>

export default function LoginForm() {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const {isMobileApp} = useMobileAppDetect()
  const callbackUrl = useSearchParams()?.get('callbackUrl')
  const router = useRouter()
  const form = useForm<LoginFormValues>({
    resolver: zodResolver(LoginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit: SubmitHandler<LoginFormValues> = async (data) => {
    const cookieConsent = Cookies.get('cookieConsent')

    if (cookieConsent !== 'true') {
      setError('Please accept cookies to continue')
      return
    }

    const {email, password} = data
    const res = await signIn('credentials', {
      email,
      password,
      domain: window.location.host,
      redirect: false,
    })
    if (res?.error) {
      setError(displayError(res.error))
      return
    }

    setIsFormSubmitted(true)
    const callback = callbackUrl ?? '/'
    router.push(callback as string)
    return
  }

  const [formValueEmail, formValuePassword] = form.watch(['email', 'password'])

  useEffect(() => {
    router.refresh()
  }, [router])

  useEffect(() => {
    const formValues = form.getValues()

    if (form.formState.isSubmitting || form.formState.isValidating || isFormSubmitted) {
      setIsSubmitDisabled(true)
    } else if (!formValues.email || !formValues.password) {
      setIsSubmitDisabled(true)
    } else {
      setIsSubmitDisabled(false)
    }
  }, [
    form,
    isFormSubmitted,
    form.formState.isSubmitting,
    form.formState.isValidating,
    formValueEmail,
    formValuePassword,
  ])

  return (
    <>
      <Form {...form}>
        <Message error={error} />
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
          <FormField
            control={form.control}
            name='email'
            render={({field}) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} autoCapitalize='none' autoComplete='email' autoCorrect='off' />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='password'
            render={({field}) => (
              <FormItem className='relative'>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <div className='relative'>
                    <Input {...field} type={showPassword ? 'text' : 'password'} />
                    <Button
                      type='button'
                      variant='ghost'
                      size='icon'
                      className='absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent'
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                    >
                      {showPassword ? (
                        <EyeOff className='h-4 w-4 text-primary' />
                      ) : (
                        <Eye className='h-4 w-4 text-primary' />
                      )}
                    </Button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <ForgotPasswordDialog />
          <Button className='w-full' type='submit' disabled={isSubmitDisabled}>
            {(form.formState.isSubmitting || form.formState.isValidating || isFormSubmitted) && (
              <Loader2 className='mr-2 h-4 w-4 animate-spin' />
            )}
            Log in
          </Button>
        </form>
      </Form>
      {!isMobileApp && (
        <div className='!mt-2 text-center'>
          <div className='relative flex justify-center text-sm'>or</div>
          <Link href='/auth/register' className='text-sm font-semibold text-primary underline'>
            Create a new account
          </Link>
        </div>
      )}
    </>
  )
}

const displayError = (error: string) => {
  const knownErrors: {[key: string]: string} = {
    CredentialsSignin: 'Invalid email or password',
  }
  return knownErrors[error] ?? 'Something went wrong'
}

const Message = ({error}: {error: string}) => {
  let message = useSearchParams()?.get('message')
  let title = 'Login failed'
  let variant: 'destructive' | 'default' = 'destructive'
  let description = error

  if (
    ![
      'errorResetPassword',
      'successResetPassword',
      'succesCreateAccount',
      'errorCreateAccountTokenUsed',
      'errorCreateAccountTokenExpired',
      'errorCreateAccountTokenNotFound',
    ].includes(message)
  ) {
    message = ''
  }

  switch (message) {
    case 'errorResetPassword':
      title = 'Password reset unsuccessful'
      description =
        'The password reset link was invalid, possibly because it has already been used. Please request a new password reset.'
      break
    case 'errorCreateAccountTokenUsed':
      title = 'This confirmation token was already used'
      description = 'Please try logging in or resetting your password.'
      break
    case 'errorCreateAccountTokenExpired':
      title = 'This confirmation token has expired'
      description = 'Please try signing up again'
      break
    case 'errorCreateAccountTokenNotFound':
      title = 'Could not find confirmation token'
      description = 'Please try signing up again'
      break
    case 'successResetPassword':
      variant = 'default'
      title = 'Password reset was successful!'
      description = 'Enter your new credentials to Coop+.'
      break
    case 'succesCreateAccount':
      variant = 'default'
      title = 'You have successfully activated your account in Knowledge Coop+.'
      description = ''
      break
  }

  return (
    <>
      {(error || message) && (
        <Alert variant={variant}>
          {['successResetPassword', 'succesCreateAccount'].includes(message) ? (
            <PartyPopperIcon className='h-5 w-5' />
          ) : (
            <AlertCircle className='h-4 w-4' />
          )}
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription>{description}</AlertDescription>
        </Alert>
      )}
    </>
  )
}
