'use client'

import Cookies from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'
import {useEffect, useState} from 'react'

import {Button} from '@/components/ui/button'
import useMobileAppDetect from '@/lib/device-detect'
import cookieCooper from '@/public/cookieCooper.png'

export const CookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useState(true)
  const {isMobileApp} = useMobileAppDetect()
  if (isMobileApp) {
    Cookies.set('cookieConsent', 'true')
  }

  const cookieConsentClick = () => {
    setCookieConsent(true)
    Cookies.set('cookieConsent', 'true')
  }
  useEffect(() => {
    if (Cookies.get('cookieConsent') !== 'true') {
      setCookieConsent(false)
    }
  }, [])

  if (cookieConsent) return null

  return (
    !cookieConsent && (
      <div className='absolute inset-0 flex items-center justify-center'>
        <div className='sticky-0 fixed top-0 z-10 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-80'>
          <div className='flex min-h-[50%] min-w-[50%] items-center justify-center p-4'>
            <div className='relative flex h-full w-full flex-col items-center justify-center rounded-lg bg-white p-4 dark:bg-gray-800 md:w-3/4'>
              <Image src={cookieCooper} alt='Cookie Cooper' className='ml-8 w-full max-w-64 object-contain' priority />
              <div>
                <h1 className='text-center text-2xl font-semibold dark:text-white lg:text-3xl'>Cooper loves cookies</h1>
              </div>
              <div className='my-5'>
                <p className='text-center text-sm leading-5 dark:text-white'>
                  This site uses services that uses cookies to deliver better experience and analyze traffic.
                  <br></br>
                  You can learn more about the services we use at our{' '}
                  <Link
                    href='https://help.knowledgecoop.com/en/knowledge/knowledge-coop-privacy-policy'
                    target='_blank'
                    className='hover:text-lightAccent text-xs underline'
                  >
                    privacy policy
                  </Link>
                  .
                </p>
              </div>
              <Button variant='default' className='w-full' onClick={() => cookieConsentClick()}>
                Accept
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
