'use client'

import {Loader2} from 'lucide-react'
import Link from 'next/link'
import {useEffect, useState} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {z} from 'zod'

import {Button} from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from '@/components/ui/form'
import {Input} from '@/components/ui/input'
import {getBaseUrl} from '@/lib/auth/utils'
import {zodResolver} from '@hookform/resolvers/zod'

const ForgotPasswordFormSchema = z.object({
  email: z.string().email({message: 'Enter valid email address(Example: john@gmail.com)'}),
})

export type ForgotPasswordFormValues = z.infer<typeof ForgotPasswordFormSchema>

export default function ForgotPasswordDialog() {
  const [open, setOpen] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const form = useForm<ForgotPasswordFormValues>({
    resolver: zodResolver(ForgotPasswordFormSchema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit: SubmitHandler<ForgotPasswordFormValues> = async (data) => {
    await fetch(`${getBaseUrl()}/account/api/reset-password/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
      }),
    })
    setIsFormSubmitted(true)
  }

  const [formValueEmail] = form.watch(['email'])

  useEffect(() => {
    const formValues = form.getValues()

    if (form.formState.isSubmitting || form.formState.isValidating || isFormSubmitted) {
      setIsSubmitDisabled(true)
    } else if (!formValues.email) {
      setIsSubmitDisabled(true)
    } else {
      setIsSubmitDisabled(false)
    }
  }, [form, form.formState.isSubmitting, form.formState.isValidating, formValueEmail, isFormSubmitted])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Link href='' className='!mt-0 w-full text-right text-sm text-primary hover:underline'>
          Forgot your password?
        </Link>
      </DialogTrigger>
      {isFormSubmitted ? (
        <DialogContent className='sm:max-w-md' displayCloseBtn={false}>
          <DialogHeader className='text-left'>
            <DialogTitle className='mb-2 text-2xl font-bold text-primary'>Password Reset</DialogTitle>
            <DialogDescription>
              A link to reset your password has been sent to your email. Please check your email for further
              instructions.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className='sm:justify-start'>
            <DialogClose asChild>
              <Button
                variant='secondary'
                onClick={() => {
                  setOpen(false)
                  setIsFormSubmitted(false)
                  form.reset()
                }}
              >
                Log in
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      ) : (
        <DialogContent className='sm:max-w-md'>
          <DialogHeader className='text-left'>
            <DialogTitle className='mb-2 text-2xl font-bold text-primary'>Password Reset</DialogTitle>
            <DialogDescription>
              If you have already purchased courses on www.knowledgecoop.com, enter the same email and password used
              during checkout.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={(e) => {
                e.stopPropagation()
                return form.handleSubmit(onSubmit)(e)
              }}
              className='space-y-6'
            >
              <FormField
                control={form.control}
                name='email'
                render={({field}) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} autoCapitalize='none' autoComplete='email' autoCorrect='off' />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type='submit' disabled={isSubmitDisabled}>
                {(form.formState.isSubmitting || form.formState.isValidating) && (
                  <Loader2 className='mr-2 h-4 w-4 animate-spin' />
                )}
                Send Email
              </Button>
            </form>
          </Form>
        </DialogContent>
      )}
    </Dialog>
  )
}
